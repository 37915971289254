
.modal {
    z-index: 3000 !important;
    display: block !important;
}
/*.modal {
    z-index: 12;
    margin: 50px auto;
    background-color: white;
    padding: 5px;
    position: fixed;
    top: 50px;
    left: calc(50% - 175px);
    z-index: 10;
    border-radius: 0;
    text-align: center;
    max-height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}
.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem!important;
    pointer-events: none;
    max-width: unset!important;
}

.modal-content {
    width: 100%;
    background-color: #fff;
    border: none;
    border-radius: 0;
    outline: 0;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;
    background-color: #000;
}
.info-modal {
    z-index: 12!important;
}
.modal-backdrop.show {
    opacity: 0.7;
}


.info-modal {
    width: 350px;
    margin: 50px auto;
    background-color: white;
    padding: 5px;
    position: fixed;
    top: 50px;
    left: calc(50% - 175px);
    z-index: 10;
    border-radius: 0;
    text-align: center;
}
.info-modal .inner {
    border: none;
    padding: 30px 15px 15px 15px;

}
.info-modal .close {
    color: #000!important;
    font-size: 27px;
    position: absolute;
    top: 10px;
    right: 18px;
}
.info-modal h3 {
    font-size: 30px;
}
.info-modal h4 {
    font-size: 22px;
    font-weight: 300;
}
.info-modal p {
    font-weight: 300;

}
.modal-sm {
    width: 350px;
}
.modal-md {
    width: 450px;
}
.modal-lg {
    width: 700px;
    left: calc(50% - 250px);
    display: block;
}

.modal-xl {
    width: 95%;
    left: 2.5%;
    display: block;
}
.modal-login {
    width: 95%;
    left: 2.5%;
    display: block;
    height: 90vh;
}
@media (min-width: 992px) {
    .modal-xl {
        max-width: 1200px;
        left: calc(50% - 590px);
    }
    .modal-login {
        max-width: 1200px;
        left: calc(50% - 590px);
        display: block;
        max-height: 600px;
        overflow: hidden;
    }

}*/



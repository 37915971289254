/*app-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
}*/
.container-menu {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1195px;
}
.header-item {
    flex-grow: 1;
    align-self: center;
    color: #263847 !important;
}
.header-sub-item {
    margin-right: 32px;
    color: #263847 !important;
    font-size: 15px;
    position: relative;
}
.header-sub-item,
.header-sub-item-icon {
    text-decoration: none !important;
}
.header-sub-item-icon {
    margin-right: 32px;
    color: #263847 !important;
    font-size: 19px;
    position: relative;
}

.header-sub-item-icon .badge-count {
    background-color: red;
    color: #fff;
    padding: 4px;
    border-radius: 50%;
    top: -1px;
    left: 15px;
    width: 22px;
    height: 22px;
    position: absolute;
    font-size: 11px;
    line-height: 12px;
    text-align: center;
}
.header-item a {
    font-size: 16px;
    font-family: sans-serif;
    color: #263847 !important;
}
.header-item img {
    max-height: 45px;
}
#header .navbar-dark a {
    font-family: "Spectral" !important;
    color: white !important;
    font-size: 13px;
}
#header .nav-item {
    text-align: center;
    width: 100%;
    max-width: 120px;
}
#header .navbar-nav .dropdown-menu {
    position: static;
    float: none;
    background-color: #fff;
    color: #475763;
}
#header .dropdown-menu {
    min-width: 850px;
    border-radius: 0;
    border: none;
}
#header .dropdown-menu-option {
    width: 150px;
    display: inline-block;
    margin: 0 10px 25px 0;
    border-bottom: 2px solid #fff;
}
#header .dropdown-menu a.dropdown-item {
    color: #475763 !important;
}
#header .dropdown-menu-option:hover {
    border-bottom: 2px solid #c2ebff;
    background-color: #fff;
}
#header .dropdown-item:focus,
.dropdown-item:hover {
    text-decoration: none;
    background-color: #fff;
}
#header .menu-links-holder {
    width: 740px;
    padding: 48px 30px;
}

#header .menu-image-holder {
    width: 260px;
    padding: 30px;
}
#header .menu-image {
    height: 200px;
    width: 200px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.promoBarOption {
    font-family: "Spectral" !important;
    max-width: 33%;
    min-width: 25%;
    text-align: center;
    color: #263847;
    font-size: 14px;
    line-height: 21px;
}
#header .btn-menu {
    text-align: center;
    width: 100%;
}

#header #header-nav ul li:hover > div {
    display: block;
}
#header #header-nav ul li > div {
    display: none;
    position: absolute;
    top: 44px;
    left: 0;
    text-align: left;
    background: #fff;
    color: #3d4d59;
    z-index: 30;
    width: 800px;
    height: 260px;
}
#header #header-nav ul li > div a {
    text-align: left;
    color: #3d4d59 !important;
    margin: 0;
    padding: 0;
}
#header .nav-link.dropdown-toggle {
    line-height: 34px;
}

.menu-holder {
    background-color: #263947;
    width: 252px;
    height: 100%;
    padding: 0;
    position: absolute;
    z-index: 0;
    top: 0;
    margin-left: -252px;
    overflow: hidden;
    color: #FFF;
}

.logo-container {
    height: 88px;
    background-color: #324350;
    width: 100%;
    position: absolute;
    top: 0;
    width: 252px;
    line-height: 18px;
    padding: 35px 43px;
}
.logo-container p {
    color: #fff;
    opacity: 0.59619140625;
    margin: 0;
}
.menu-container {
    height: calc(100% - 88px);
    overflow-y: auto;
    overflow-x: hidden;
}
.menu-holder .list-group {
    padding: 15px 0;
}

.menu-holder .list-group-item {
    position: relative;
    display: block;
    padding: .20rem 0.5rem .30rem 2.75rem;
    margin-bottom: -1px;
    background-color: #263947;
    color: #DCDFE2;
    border: none;
    border-radius: 0;
    font-family: ArialMT;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}
.menu-holder .list-group-item-title {
    opacity: 0.5;
    font-size: 12px;
    font-family: 'Spectral' !important;
    ont-weight: 500;
}

.menu-holder .list-group-item.active {
    background-color: #FAFF5D;
    border-color: #FAFF5D;
    color: #263947;
}

.menu-holder i.menu-icon {
    font-size: 19px;
    float: unset;
    margin-right: 8px;
}

.menu-holder .account-info {
    padding: .20rem 0.5rem .30rem 2.75rem;
    color: #DCDFE2;
    font-family: ArialMT;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
}
.menu-holder .account-info h3 {
    color: #DCDFE2!important;
    margin-bottom: 0;
    font-size: 12px;
    opacity: 0.5;
}


@media (min-width: 992px) {
    .menu-holder {
        margin-left: 0;
    }

}

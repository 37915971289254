/* You can add global styles to this file, and also import other style files */

// Configuration
@import "assets/css/functions";
@import "assets/css/variables";
@import "assets/css/mixins";
@import "../node_modules/bootstrap/scss/utilities";

// Font Awesome
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";

// Layout & components
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/tables";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/button-group";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/accordion";
@import "../node_modules/bootstrap/scss/breadcrumb";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/badge";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/progress";
@import "../node_modules/bootstrap/scss/list-group";
@import "../node_modules/bootstrap/scss/close";
@import "../node_modules/bootstrap/scss/toasts";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/tooltip";
@import "../node_modules/bootstrap/scss/popover";
@import "../node_modules/bootstrap/scss/carousel";
@import "../node_modules/bootstrap/scss/spinners";

// Helpers
@import "../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../node_modules/bootstrap/scss/utilities/api";

@import "./assets/css/keen-slider.css";
@import "@angular/cdk/overlay-prebuilt.css";
@import "./assets/css/dragula.css";
@import "./assets/css/header.css";
@import "./assets/css/footer.css";
@import "./assets/css/typography.css";
@import "./assets/css/framework.css";
@import "./assets/css/forms.css";
@import "./assets/css/login.css";
@import "./assets/css/modal.css";
@import "./assets/css/sweetalert.css";
@import "./assets/css/cms.css";
@import "./assets/css/blog.css";
@import "./assets/css/buttons.css";
@import "./assets/css/dropzone.css";
@import "./assets/css/basket.css";
@import "./assets/css/products.css";
@import "./assets/css/user-menu.css";
@import "./assets/css/pagination.css";
@import "./assets/css/aside.css";
@import "./assets/css/chat.css";
@import "./assets/css/mail.css";
@import "./assets/css/breadcrumb.css";
@import "./assets/css/stylesheet.css";
@import "./assets/css/spacing.css";
@import "./assets/css/mobile.css";
@import "./assets/css/custom.inputs.scss";
@import "./assets/css/buttons.scss";


html,
body {
    height: 100%;
}

body {
    margin: 0;
}

.btn:focus,
button:focus,
.btn-primary:focus {
    outline: none;
    box-shadow: none;
}

.mr-0 {
    margin-right: 0 !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.info-modal.custom-z-index {
    z-index: 9999 !important;
}


.header-padding {
    padding-right: 30px;
    padding-left: 30px;
}

.header-right {
    .header-sub-item-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px !important;
        color: #263847 !important;
        font-weight: normal;

        svg {
            max-width: 20px;
        }

        .dropdown-menu-end {
            top: 30px;
        }
    }

}




/*main user dashboard css*/

.user-table-main {
    padding: 50px 30px;

    .data-container {
        padding: 30px 0;

        .preview-box {
            padding: 30px;
            margin-bottom: 0;

            .preview-box-title {
                font-size: 20px;
                font-weight: 600;
            }

            .preview-box-content {
                padding-top: 40px;

                .table {
                    thead {
                        th {
                            color: #263847;
                            font-size: 18px;
                            font-weight: 500;
                            vertical-align: top;
                        }
                    }

                    tbody {
                        td {
                            border-bottom: 0;
                            color: #324350 !important;
                            letter-spacing: 0.3px;
                        }
                    }

                    tr {
                        border-bottom: 1px solid #dee2e6;
                    }

                    .read {
                        opacity: 1;
                    }
                }

                .vehicle-tile {
                    .vehicle-tile-content {
                        h3 {
                            text-align: left !important;
                            font-size: 20px;
                            font-weight: 600;
                            color: #263847 !important;
                            margin-bottom: 15px;
                        }

                        .lorries-detail {
                            border-top: 1px solid #F0F1F2;
                            padding: 5px 0;

                            p {
                                margin-bottom: 0 !important;
                            }

                            .sub-title {
                                flex: 1;
                            }

                            .sub-data {
                                flex: 2;
                            }
                        }
                    }
                }

                .nav-pills {
                    .nav-link {
                        color: #263847;
                    }

                    .nav-link.active {
                        color: #fff;
                    }

                }
            }
        }

        &:first-of-type {
            padding-top: 0;
        }
    }

    .table-responsive .table> :not(:first-child) {
        border-top: 0px solid currentColor !important;
    }

    .table> :not(caption)>*>* {
        padding: 17px;
    }

    .owner-heading {
        font-size: 18px;
        font-weight: 500;
        color: #263847;
    }


    .transparent-btn {
        border: 1px solid #DCDFE2;
        background-color: transparent;

        &:hover {
            background-color: #C2EBFF;
            border: 1px solid #C2EBFF;
        }
    }

    .light-blue-btn {
        background-color: #C2EBFF;
        border: 1px solid #C2EBFF;
    }

    .btn {
        font-size: 12px;
        padding: 8px 20px;
        border-radius: 20px;
        // color: #263847;
    }

    .slide-box-show {
        .row {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }

}

@media only screen and (max-width: 1600px) {
    .user-table-main .job-board-table .preview-box .preview-box-content .table thead th {
        padding: 5px;
        font-size: 14px;
    }

    .user-table-main .job-board-table .table> :not(caption)>*>* {
        padding: 5px;
    }

    .user-table-main .job-board-table .preview-box .preview-box-content .table tbody td {
        font-size: 12px !important;
        line-height: 1.3;
    }
}


/* modal dialog css for order details*/

app-order-overview-modal {
    #order-details {
        .modal-title, .alert h5 {
            font-size: 1rem;
            font-family: system-ui, -apple-system, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, 
            Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
            font-weight: 500;
        }
        small {
            font-size: 14px !important;
        }
    }
}

@media only screen and (min-width: 1500px) {
    app-order-overview-modal {
        #order-details {
            .modal-dialog.modal-xl {
                max-width: 1440px !important;
            }
        }
    }
}
